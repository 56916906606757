<template>
  <div style="height: 44px;background-color: #f4f4f4;display: flex;padding: 2px;border-radius: 2px;width: fit-content;color: #999999;font-size: 15px;">
    <div
        v-for="item in list"
        :class="{active: item.value === checked}"
        :key="item.value" style="border-radius: 2px;min-width: 100px;text-align: center;padding: 10px;cursor: pointer"
        @click="$emit('change', item.value)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "HiRadio",
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: [String, Number]
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style scoped>
.active{
  background-color: white;
  color: #333333;
  font-weight: 600;
}
</style>
