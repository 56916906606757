<template>
  <div>
    <div style="width: 100%">
      <el-dropdown>
        <span class="el-dropdown-link">
          <span style="font-size: 20px;font-weight: 600;color: #333333">TME</span><i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>TME</el-dropdown-item>
          <el-dropdown-item disabled>网易</el-dropdown-item>
          <el-dropdown-item disabled>抖音</el-dropdown-item>
          <el-dropdown-item disabled>快手</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div style="display: flex;justify-content: space-between;align-items: flex-end;margin-top: 20px">
      <hi-radio :list="[{label: '近七天', value: 0},{label: '近一个月', value: 1},{label: '近两年', value: 2}]" v-model="time"></hi-radio>
      <el-input v-model="search" clearable @change="getSongHistory" placeholder="专辑/歌曲名称" prefix-icon="el-icon-search" style="max-width: 275px"></el-input>
    </div>

    <div style="display: flex;gap: 20px;margin-top: 20px">
      <div class="number-card">
        <div style="display: flex;flex-direction: column;justify-content: space-between">
          <div style="color: #666666">总播放指数</div>
          <div style="color: #333333;font-size: 24px;font-weight: 550;line-height: 1">
            {{ response.releaseSongCntVo ? response.releaseSongCntVo['playCnt' + suffix[time]].toFixed(2) : '-' }}
          </div>
        </div>
        <el-image :src="number3"></el-image>
      </div>
      <div class="number-card">
        <div style="display: flex;flex-direction: column;justify-content: space-between">
          <div style="color: #666666">总收藏指数</div>
          <div style="color: #333333;font-size: 24px;font-weight: 550;line-height: 1">
            {{ response.releaseSongCntVo ? response.releaseSongCntVo['favCnt' + suffix[time]].toFixed(2) : '-' }}
          </div>
        </div>
        <el-image :src="number1"></el-image>
      </div>
      <div class="number-card">
        <div style="display: flex;flex-direction: column;justify-content: space-between">
          <div style="color: #666666">总下载指数</div>
          <div style="color: #333333;font-size: 24px;font-weight: 550;line-height: 1">
            {{ response.releaseSongCntVo ? response.releaseSongCntVo['downloadCnt' + suffix[time]].toFixed(2) : '-' }}
          </div>
        </div>
        <el-image :src="number2"></el-image>
      </div>
    </div>
<!--    排序: 0播放指数优先; 1下载指数优先; 2收藏指数优先 3最新-->
    <el-dropdown style="margin-top: 56px" @command="handleCommand">
      <span class="el-dropdown-link">
        {{ ['最热优先', '下载指数优先', '收藏指数优先', '最新优先'][sort] }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="0">最热优先</el-dropdown-item>
        <el-dropdown-item :command="3">最新优先</el-dropdown-item>
        <el-dropdown-item :command="1">下载指数优先</el-dropdown-item>
        <el-dropdown-item :command="2">收藏指数优先</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-table
        :data="response.list"
        style="width: 100%">
      <el-table-column
          prop="trackName"
          label="歌曲名称"
          width="180"
          v-slot="{ row }">
        {{ row.trackName + (row.trackVersion ? '(' + row.trackVersion + ')' : '') }}
      </el-table-column>
      <el-table-column
          prop="albumName"
          label="专辑名称"
          width="180">
      </el-table-column>
      <el-table-column
          label="播放指数"
          v-slot="{ row }">
        {{ row['playCnt' + suffix[time]].toFixed(2) }}
      </el-table-column>
      <el-table-column
          label="收藏指数"
          v-slot="{ row }">
        {{ row['favCnt' + suffix[time]].toFixed(2) }}
      </el-table-column>
      <el-table-column
          label="下载指数"
          v-slot="{ row }">
        {{ row['downloadCnt' + suffix[time]].toFixed(2) }}
      </el-table-column>
      <el-table-column
          label="操作"
          v-slot="{ row }">
        <el-link type="primary" @click="$router.push(`trend/${row.tmeId}-${['week', 'mon', 'year'][time]}-${row.tmeCompanyId}?data=` + encodeURI(JSON.stringify(row)))">最新趋势</el-link>
      </el-table-column>
    </el-table>

    <div style="text-align: right;margin-top: 10px">
      <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getSongHistory"
          :current-page.sync="pageNum"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import HiRadio from "@/components/HiRadio"
import number1 from "@/assets/number-1.png"
import number2 from "@/assets/number-2.png"
import number3 from "@/assets/number-3.png"
import {songHistory} from "@/api/song"

export default {
  name: "trends",
  components: {
    HiRadio
  },
  data(){
    return {
      suffix: ['Week', '', 'Years'],
      response: {},
      pageNum: 1,
      total: 0,

      time: 0,
      search: '',
      number1,
      number2,
      number3,

      // 排序: 0播放指数优先; 1下载指数优先; 2收藏指数优先 3最新
      sort: 0
    }
  },
  mounted() {
    this.getSongHistory()
  },
  methods: {
    handleCommand(c){
      this.sort = c
      this.getSongHistory()
    },
    getSongHistory(){
      songHistory({
        "name": this.search,
        "order": 0,
        "pageNum": this.pageNum,
        "pageSize": 10,
        "sort": this.sort
      }).then(r => {
        this.response = r.data
        this.total = this.response.total
      })
    }
  }
}
</script>

<style scoped>
.number-card{
  background-color: #fafafa;
  height: 59px;
  width: 100%;
  padding: 23px 28px 18px 30px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}
</style>
